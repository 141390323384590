.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #fffaf1;
        font-size: 53px; // Change
        margin: 0;
        font-family: 'Coolvetica'; // Change
        font-weight: 400;
        
        &:before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #5a86ad;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: -15px;
            opacity: 0.6;
        }

        &:after {
            content: '<h1/>';
            font-family: 'La Belle Aurore';
            color: #5a86ad;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        img {
            width: 50px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20x;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #5a86ad;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #5a86ad;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #5a86ad;
            color: #333;
        }
    }
}